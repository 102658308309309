export default {
	crypto: {
		title: {
			0: "Krypto satın alın",
			1: "Krypto satın alın",
			2: "C2C",
		},
		list: {
			0: "C2C Trading",
			1: "Alın.",
			2: "Satın",
			3: "Reklamcılar (Tamamlama hızı)",
			4: "Tradi",
			5: "Ödül",
			6: "Mevcut",
			7: "Sınır",
			8: "Ödeme Yöntemi",
			9: "Satış",
			10: "Reklamcısı reklamın transaksyon sınırlarını yerleştirebilir ve girdikten sonra sınırlama sebebini göreceksiniz.",
		},
		buy: {
			0: "Krypto satın alın",
			1: "Şifreleri satın",
			2: "Tarihi",
			3: "Destek",
			4: "Ödeme",
			5: "Alın",
			6: "Ödeme yöntemini seç",
			7: "Est. Gelme zamanı",
			8: "FEE",
			9: "Sıra Ayrıntıları",
			10: "Ödeme yöntemi",
			11: "Daha fazla öğrenin",
			12: "Ödül",
			13: "Toplam (ücret dahil)",
			14: "Kaçırıcı",
			15: "HKDFinanc'dan ayrılıyorsunuz ve üçüncü parti olarak kredi/debet kartı ödeme hizmeti sağlayan lisanslı finans kuruluşu Mercuryo'ya yeniden yönlendirileceksiniz. Servis kullanımında oluşan her riski Mercuryo tarafından yüklenecek. Lütfen devam etmeden önce kullanma şartları okuyun ve kabul edin.",
			16: "Kullanma Vakitlerini okudum ve kabul ettim.",
			17: "Sen alacaksın.",
			18: "Teslim et",
			19: "Lütfen miktarı girin",
			20: "Yetersiz hesap dengesi",
			21: "Mevcut",
			22: "Nota Hesabı Mevcut",
			23: "Tek satın sınırı",
			24: "Minimum",
			25: "Maksimum",
		}
	},
	common: {
		confirm: "Tanıyın",
		cancel: "Abolish",
		tip: "prompts",
		place_select: "Lütfen seçin",
		submit: "Teslim et ",
		more: "Daha fazla",
		deposit: "Depozit",
		real_trade: "Gerçek anlaşma",
		paper_trade: "Demo Trading",
		build_paperTrade: "Demo hesabı oluştur",
		start_trade: "Trading Şimdi Başlat",
		app_name: "HKDFINANC",
		success: "Başarılar！",
		dataSources_name: "Trading Central",
		reset: "Yeniden değiştirme",
		iknow: "Anladım.!",
		noData: "Veri mevcut değil",
		modify: "Değiştirmeler",
		pingcang: "Bir pozisyonu kapat",
		all: "Tam",
		placeEnter: "Lütfen girin.",
		countryCode: "Ülke kodu",
	},
	addnew: {
		realFirst: "Lütfen ilk kimlik kimliğini gerçekleştirin",
		payFirst: "Lütfen önce transaksyon parolanı ayarlayın",
		historyOrder: "Tarihi emirler",
		forgot: "Parola unut",
		contractTrade: "Kontrakt",
		paypass: "Satış Parola",
		loginpassTit: "Giriş parolanı değiştir",
		paypassTit: "Çıkarma parolanı değiştir",
		paypassTit1: "Lütfen çekilme parolanızı ayarlayın",
		paypassTit2: "Parolayı giriş parolanıza başlatın.",
		buyUp: "Alın.",
		buyDown: "Aşağı al",
		addressName1: "Sino-Gold Financial (International) Holdings Limited",
		addressValue1: "Oda 1101, 11. katı, Crown Tower (eski Citibank Kulesi), 3 Garden Road, Central, Hong Kong.",
		addressName2: "Hong Kong Sino Finans Yatırımı sınırlı",
		addressValue2: "131-133 Queen's Road Central, Zhongcai Center, Central, Hong Kong ",
		addressName3: "Prosperity Financial Holdings Limited",
		addressValue3: "Oda 1908, 19. katı, 89 Queensway, Admiralty, Hong Kong Lippo Merkez Kulesi 2",
		loan: "Loan",
		repaid: "Tekrarlama",
		unpaid: "Ödemez",
		loanMore: "Kredi istiyorum.",
		immediateRepayment: "Derhal Tekrarlama",
		accountType1: "Şifreleme Hesabı",
		accountType2: "Kontrakt hesabı",
		accountType3: "Seçenekler hesapları",
		from: "İtiraf et ",
		to: "- Evet.",
		get: "Yakalayacağım.",
		labelBreed: "Para",
		placeBreed: "Lütfen para seçin",
		labelTransAccount: "Hesap",
		labelAmount: "Kıymet",
		placeAmount: "Lütfen aktarım miktarını girin",
		transferTit:"Transfer",
		convertTit:"Döndür",
		balanceTit:"Tahmin edilen",
		available: "Mevcut",
		pending: "Bir çantayla kapat.",
		equivalent: "Böyle eşit olun.",
		coinTrade: "Şifreleme Tuzağı",
		secondContract: "İkinci anlaşma",
		number: "Kıymetler",
		labelNumber: "Kıymetler",
		placeNumber: "Lütfen fidye sayısını girin",
		time: "Zaman",
		loadAmount: "Beklenmiş kredi miktarı",
		repaymentCycle: "Kredi ödeme döngüsü",
		dailyRate: "Günlük hızı",
		repaymentMethod: "Tekrarlama metodu",
		loanTip: "Kredi Kredi (Lütfen resmin açık görünür olup olmadığından emin olun)",
		loanTip1: "Özellik sertifikasını yükle",
		loanTip2: "Geldi kanıtı (işe)",
		loanTip3: "Banka ifadelerinin detayları",
		loanTip4: "Kimlik kartınızın ön fotoğrafını yükleyin",
		interest: "İlginç (istek sth hakkında bilsin)",
		repaymentMethod1: "bir toplam ödeme için",
	},
	params: {
		product_foreign: "Forex",
		product_shop: "Merkez",
		product_number: "Görüntüler",
		product_stock: "Stock",
		product_coin: "Şifreler",
		billTypes: [
			{v: 101, name: 'Tekrar yükle'},
			{v: 102, name: 'Depozit'},
			{v: 103, name: 'Dondu'},
			{v: 104, name: 'Dondurma'},
			{v: 105, name: 'Depozit'},
			{v: 106, name: 'Depozit'},
			{v: 201, name: 'Dondurma kaldır'},
			{v: 202, name: 'Parayı çek'},
			{v: 203, name: 'Kaçırma başarılı.'},
			{v: 204, name: 'Parayı geri çekmeye başarısız'},
			{v: 205, name: 'Kaçırma ücreti'},
			{v: 206, name: 'Transfer dışarı'},
			{v: 207, name: 'Yönlendir'},
			{v: 208, name: 'Coin Transfer'},
			{v: 209, name: 'Coin Transfer'},
			{v: 301, name: 'Kontrakt'},
			{v: 302, name: 'Anlaşmalı gelir'},
			{v: 303, name: 'Anlaşma kaybı'},
			{v: 304, name: 'Kontrakt sınırı'},
			{v: 305, name: 'Kontrakt Margin Return'},
			{v: 311, name: 'Alış Seçenekleri'},
			{v: 312, name: 'İçeri seçenekleri'},
			{v: 313, name: 'Seçenek geri dönüyor'},
			{v: 314, name: 'İhtiyariyat Yönetim Beslemesi'},
			{v: 315, name: 'Coin Buy Freeze'},
			{v: 316, name: 'Coin Buy Deduction'},
			{v: 317, name: 'Coin Buy Returns'},
			{v: 318, name: 'Coin Buy to Account'},
			{v: 319, name: 'Coin Buy to Account'},
			{v: 320, name: 'Coin Selling Freeze'},
			{v: 321, name: 'Coin Sell Deduction'},
			{v: 322, name: 'Coin Sell Returns'},
			{v: 323, name: 'Coin Sell to Account'},
			{v: 324, name: 'Coin Sell to Account'},
			{v: 325, name: 'Coin Handling Fee'},
			{v: 401, name: 'Miners katılıyor.'},
			{v: 402, name: 'Miner geri dönüyor.'},
			{v: 403, name: 'Minik gelirleri'},
			{v: 404, name: 'Mini Çıkış'},
			{v: 405, name: 'Minik Çıkış Beslemesi'},
			{v: 411, name: 'Ödünç almak'},
			{v: 412, name: 'Ödünç Beslemesi'},
			{v: 413, name: 'Başarılı ödeme'},
		],
	},
	message: {
		logout: "Çıkış",
		register_success: "Başarılı kayıt",
		place_inner_personal: "Lütfen önce kişisel bilgilerinizi girin",
		submit_success: "Başarılı gönderildi！",
		copy_success: "Başarılı kopyalama！",
		copy_error: "Reproduction Failure！",
		modify_success: "Başarılı değiştirildi",
		no_balance: "Güvenlik depozitini kapatmak için yetersiz denge",
		palce_password: "Lütfen parolanızı girin",
		trade_success: "Başarılı işlem",
		placeEnterAmount: "Lütfen miktarı girin",
		tipTit1: "Bu emir için ödemek istediğinizden emin misiniz?",
	},
	web: {
		account: {
			menu1: "Hesap Bilgisi",
			menu2: "Benim mallarım",
			menu3: "Hesap Raporları",
			menu4: "Hesap Güvenliği",
			menu5: "Bildirimler",
			menu6: "Etkinlik Merkezi",
			menu7: "Cüzdanım",
			menu8: "Çıkarma",
			walletAdd: "Yeni Cüzdanı Ekle",
			report: {
				history_tit: "Transaksyon Tarihi Raporu",
				stream_tit: "Para kaynağı raporu",
				desc: "Lütfen hesap raporunu dışarı etmek için uygun tarih menzilini seçin. (Son 1 yıl hesap bilgilerine erişiminiz olacak)",
				select_date: "Tarih menzilini seç",
				week: "Geçen hafta",
				month: "Yaklaşık bir ay.",
				threeMonth: "Üç ay.",
				sixMonth: "Altı ay.",
				time: "Zaman",
				view_report: "Raporu Göster",
				email_send: "E-posta ile gönderin",
				dialog_tit: "Hesap Raporları",
				dialog_p1: "HKDFINANC Holding Ltd, Cayman Adaları'nda kayıtlı (SIB numarası: 1612446) ve işlerinizi bir ticaret kurumu olarak işliyor.",
				tit2: "Mitade Transaction Tarihi Raporu",
				tit4: "Mitade Para Çeviri raporu",
				tit3: "Hesap Bilgisi",
				label1: "Hesap ismi",
				label2: "Hesap Kimligi",
				label3: "Hesap Valisi",
				label4: "Rapor zamanı",
				label5: "Rapor dönemi",
				label6: "Para akışı",
				empty: "Kapalı ticaret yok.",
				email_des: "Rapor e-posta adresine gönderilecek",
				tab1: "Rapor",
				tab2: "Günlük ifade",
				tab3: "Aylık ifade",
			},
			account: {
				tit1: "HKDFINANC'a hoş geldiniz",
				tit2: "Kimlik doğrulamasını tamamlayınca, canlı ortamda depozit ve ticaret yapmaya başlayabilirsiniz..",
				p1: "Canlı Hesabı Aç",
				p2: "Kimlik Doğrulaması",
				btn1: "Kimlik Doğrulaması",
				tit3: "Kişisel Bilgi",
				label_name: "İsim",
				label_email: "E-posta",
				label_address: "Yerli adres",
				tit4: "Hesap Bilgisi",
				label_account: "Hesap Hayır",
				label_currency: "Temel Valüt",
				label_company: "Güncel Hizmet Entitesi",
				company_tip: "HKDFINANC Holding Ltd Şirketlerin lisanslı ve onaylandı Kayman Adaları Monat Authority (CIMA) tarafından.",
				tit5: "Canlı Hesabı Aç",
				tit6: "Tam Profil",
				tit7: "Temel bilgilerinizi tamamlayın",
				tit8: "Risk Assessment",
				tit9: "Risk taşıma kapasitesinizi tahmin edin.",
				tit10: "Kimlik Doğrulaması",
				tit11: "Kimliğinizi düzenleme gerekçelerine uygulayıp tamamlayın",
				p3: "Lütfen doğrulamak için aşağıdaki destek belgelerinden birini seçin",
				type1: "Ulusal Kimlik",
				type2: "Sürücü Lisans",
				type3: "Pasaportu",
				p4: "Verilen tüm bilgiler ve belgeler tamamlandığını onayladım..",
				next: "Sonraki adım",
				btn2: "Kullanılmış",
				modify_personal_tit: "Kişisel bilgilerin değiştirmesi",
			},
			activity: {
				tit: "Etkinlik Merkezi",
				desc: "Yatırım gelirini arttırmak için ödülleri almak için etkinliğe katılabilirsiniz.",
			},
			capital: {
				real_trade: "Gerçek anlaşma",
				label_nowAccount: "Güncel hesap",
				label_tradeAccount: "Trading Account",
				label_currency: "Temel para",
				deposit_withdraw_record: "Kayıtları Deposit and Remove Records",
				capital_flow_details: "Fonda akışı detayları",
			},
			notification: {
				tit: "Bildirim Ayarları",
				tit1: "Bildirim kanallarını aç",
				email: "E-posta adresi",
				message: "Metin mesajlaşması",
				push: "Bastır",
				verify: "Değerlendir (bir teori)",
				verified: "Değerlendir (bir teori)",
				p1: "Lütfen önemli haberleri izlemek için aşağıdaki bildirim kanallarını açın/doğrulayın!",
				type1: "Marketing",
				tit2: "Paketleme etkinliklerinin bildirimi",
				desc2: "Platformun özel bilgi, operasyon etkinliği ve diğer haberleri",
				tit3: "İşlemler",
				desc3: "Zengin ve özel bilgi materyali",
			},
			security: {
				tit: "Kriptografik",
				isSet: "Yapılandırıldı",
				set: "Devam et ve ayarla.",
				verify: "Değerlendir (bir teori)",
				verified: "Değerlendirildi",
				tit1: "Güvenlik sertifikası",
				email: "E-posta adresi",
				phone: "Cep telefonu numarası",
				phone_tit: "Cep telefonu Numarası Bağlama",
				password_tit: "Parolayı değiştir",
			},
		},
		market: {
			hot: "Popüler",
			prev: "Öncelikle sayfa",
			next: "Sonraki sayfa",
			line: "Zaman paylaşımı",
			main_subplot: "Efendi ve Başkan Çizelge Göstericileri",
			main: "Ana grafik göstergesi",
			subplot: "Çizelge Gösterici",
			go_trade: "Transaksyon sayfasına geç",
		},
		news: {
			tip: "Kaçırıcı: Yukarıdaki bilgiler sadece referans için genel bilgi ve ticaret kararları için temel olarak kullanılmaz.",
		},
		trade: {
			state1: "Holdings",
			state2: "Bekleyen düzeni",
			state3: "Tarihi",
			state: "Modus",
			state4: "Gizli pozisyon",
			state5: "Yeniden Taşındı",
			type: "Tikoloji",
			type1: "Tam",
			type2: "Satın",
			type3: "Alın.",
			left_label1: "Kendi hizmet",
			left_label2: "Son zamanlarda görünülmüş",
			left_type1: "Kompakt Sütun Modu",
			left_type2: "Kötü sütun örneki",
			left_type3: "Çizelge Göster",
			all: "Tam",
			right_bp: "Dün hasat.",
			right_sp: "Modern günü",
			right_height: "Yüksek",
			right_low: "En aşağı",
			remind_tit: "Yeni Uyarılar",
			remindList: "Hatırlatma Listesi",
			remind_btn: "Yeni Uyarılar",
			right_tab1: "Anlaşmalar",
			right_tab2: "Özellikler",
			right_sell: "Satın",
			right_buy: "Alın.",
			right_sell1: "Satın",
			right_buy1: "Alın.",
			right_type: "Tikoloji",
			right_type1: "Pazar listesi",
			right_type2: "Bekleyen düzeni",
			right_priceMoney: "Bekleyen sıra fiyatı",
			right_pointerNumber: "Numara (çok)",
			right_lever: "Pry bar",
			right_balance: "Mevcut",
			right_stop_profit: "Parayı al.",
			right_stop_loss: "Dur-kaybı",
			right_profit_loss: "P/L",
			now_order: "Şimdi düzenle",
			confirm_order: "Düzeni doğrulama",
			right_tit1: "Trading Strategies",
			right_rinei: "Birkaç gün içinde",
			right_short: "Kısa süre",
			right_middle: "Orta süre",
			right_time: "Serbest zamanı",
			right_tit2: "Alternatif strateji",
			right_tit3: "Tehnik Görüntüle",
			right_tit4: "Dijital",
			right_tit5: "Trading mood",
			right_label_sell: "Satıcı (mal)",
			right_label_buy: "Alıcı",
			right_tip: "Sadece bilgi amaçlarına göre, Bölümün bir pozisyonu değil.",
			right_tip2: "Her 15 dakika güncelleştirildi.",
			right_tit6: "Ödül değişiklikleri",
			minute: "Minutlar",
			today: "Şu an",
			right_tit7: "Ödül değiştirme menzili",
			now_price: "Şimdiki fiyatlar",
			right_low_price: "En düşük fiyat",
			right_height_price: "En yüksek fiyat",
			right_tit8: "Anlaşma Bilgisi",
			right_tit9: "AUD/CHF",
			right_label1: "Tek işlemler sayısı",
			right_label2: "Maksimum leverage",
			right_label3: "Kontrakt boyutu",
			right_label4: "En yüksek toplam noktaların sayısı",
			right_label5: "Yılan yayılmış",
			right_label6: "Gece boyunca yük",
			hand: "Eller",
			right_collection_time: "Kolleksyon zamanı",
			right_tip3: "Besleme temeli:",
			right_label7: "Margin ratio",
			right_label8: "Maintenance margin ratio",
			right_label9: "Satış saatleri",
			right_label10: "Şimdiki Trading Sesi",
			right_label11: "Sonraki ticaret oturumu",
		},
	},
	home: {
		banner_p1: "",
		banner_p2: "Satış ",
		banner_p3: "Forex, Commodities,",
		banner_p4: "Hindistan, Paylar ve Daha fazla",
		banner_p5: "Dünyanın en s ıcak finansal pazarlarını keşfetebilirsiniz. HKDFINANC 0 komisyonu ve çok rakip fiyat farkı sunuyor.",
		hot: "Popüler",
		tit1: "Arkadaşlık aktarımları",
		p1: "Kolay operasyon için basit ve intuitiv arayüz",
		p2: "İnternet, mobil APP ve masaüstünü destekle, parmağınızla ticaret ediyor.",
		p3: "Kaybı/izleme durması gibi riskli yönetim araçları",
		p4: "Çok teknik grafik ve finansal kalendarlar, gerçek zamanlı haberler",
		p5: "Gerçek zamanlı e-posta, SMS ve bildirimleri bastırmak",
		p6: "Daha iyi bir ticaret deneyimi için sürekli geliştirme",
		btn1: "Platformumuzu keşfet",
		row3_tit: "Her zaman, her yerde",
		row3_tip: "İnternet, mobil APP ve masaüstünü destekle, parmağınızla ticaret ediyor.",
		row3_tit2: "İndirmek için kodu tarayın",
		row3_tit3: "Masaüstü",
		row3_tit4: "Mobil",
		regular_tit: "Güvenli Uluslararası Platform",
		regular_tip: "Müşterilerimiz için güvenli ve sorumlu bir ticaret ortamı sağlamaya karar verdik.",
		regular_tit1: "Bir yetkili ceset tarafından düzenlenmiş",
		regular_p1: "Endüstri yetkili düzenleme ve yetkili, küresel müşterilerin güvenini kazandı.",
		regular_tit2: "Paranın güvenliğini korumak",
		regular_p2: "Retail müşterilerin depozitleri düzenleme ihtiyaçlarına göre güven hesaplarında ayrılır.",
		regular_tit3: "Negatif denge koruması",
		regular_p3: "Sana hesap negatif denge koruması, sıfır temizlemek için negatif denge sağlayacaksınız, böylece kaybınız giriş miktarını a şmayacaksınız, akıl ticareti barışı.",
		regular_tit4: "7*24 saat müşteri hizmeti",
		regular_p4: "Dürüst ve profesyonel müşteri hizmet ekibi, 24 saat internet desteği, sahip olduğunuz her sorunu çözmek için mutlu.!",
		service_tit: "Sana yardım edecek daha fazla hizmet",
		service_tit1: "Trading Strategies",
		service_p1: "Son pazar trenlerini anlamanıza yardım etmek için gerçek zamanlı ticaret stratejileri, ticaret zamanını daha iyi anlamanız için.",
		service_tit2: "Satış Bilgi",
		service_p2: "HKDFINANC ile özgür ticaret etmeyi öğrenin ve ticaret yeteneklerinizi geliştirin..",
		service_tit3: "Risk yönetimi",
		service_p3: "HKDFINANC maliyetlerinizi daha iyi korumak için özgür risk yönetim araçlarını öğrenin.",
		step_tit: "Bir hesap açmak kolay ve uygun",
		step_tip: "Bir hesap açılmak için üç basit adım dakika içinde",
		step_tit1: "İçeri",
		step_p1: "Bilgi doldurun ve uygulamayı teslim edin",
		step_tit2: "Depozit",
		step_p2: "Çeşitli yöntemler üzerinden hızlı para depoziti",
		step_tit3: "Satış başlatımı",
		step_p3: "Satış fırsatlarını ve emirleri çabuk bulun.",
		award_tit: "Başarılarımız",
		award_p1: "Her zaman mükemmel davranıyoruz, mükemmel davranıyoruz ve müşterilerimize kalite transaksyon hizmetlerini sağlamaya karar veriyoruz..",
		award_p2: "HKDFINANC markası, sanayideki prestizci bir organizasyondan ödül almak için onur duyuyor, takımın s ürekli çabalarını ve müşterilerine bağlılığı tanıyıyor.",
		row7_tit: "Gerçek zamanlı küresel mali bilgi",
		row7_tit1: "Gerçek zamanlı haberler",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "İndirmek için tıklayın",
		p1: "Her zaman ticaret, her yerde",
		p2: "Daha hızlı ve akıllı ticaret",
		p3: "0 komisyon ticareti",
		p4: "Dünyanın en s ıcak yatırımları",
		renderDom1: "Dışişleri (para) değişikliği",
		renderDom2: "Amerikan depo pazarı",
		renderDom3: "Platinum",
		renderDom4: "Kötü yağ",
		renderDom5: "Görüntüler",
		renderDom6: "Şifresi",
		login: "İçeri gir",
		elail: "E-posta",
		phone: "Telefon numarası",
		no_account: "Hesap yok？",
		go_register: "Kayıt",
		register: "İçeri",
		now_register: "Şimdi kaydedin",
		have_account: "Zaten bir hesap var？",
		go_login: "İçeri gir.",
	},
	about: {
		serviceFee: "Servis yükü",
		introduction: "Platform İşletme",
		trade_platform: "Satış platformu",
		complaint_process: "Müzakereler süreci",
		page: "Web sayfası",
		place_search: "Bilmek istediğinizi arayın",
		no_answer: "İhtiyacınızın cevabını bulamıyorum?",
		contact_us: "Bizimle temas edin",
		awards: {
			h1: "Ödüller ve Saygılar",
			p1: "Müşterilerimize kalite ticaret hizmetlerini s a ğlamak için mükemmellik ve bağışlanma istediğimizde, HKDFINANC markası industrideki prestizci bir organizasyondan ödül almak için onur duyuyor, takımın s ürekli çabalarını ve müşterilerimize bağışlanmasını tanıyor.",
			p2: "Ya da ödülümüzü kazandığımız online ticaret platformunu hemen deneyin!",
			name1: "Latin America'daki en iyi Brokerage",
			name2: "En iyi CFD Broker",
			name3: "En iyi Fintech Broker",
			name4: "2021 yılında en çok Influential Enterprises",
			name5: "En çok Transparent Broker",
			name6: "Son Mali Teknoloji Ödülü",
		},
		charges: {
			h1: "Ödemeler ve suçlamalar",
			p1: "Müşteriler deneyimini arttırmak için çabalarımızın bir parçası olarak, HKDFINANC müşterilerimize daha pahalı ticaret hizmeti sağlamak istiyor. Ödemelerimiz ve suçlamalarımız hakkında açık ve transparent olmaya karar veriliyoruz. Müşterilerimiz için en önemli transaksyon maliyeti teklif/istek yayılmış ve gece pozisyonu ilgisi.",
			h2: "Öncelikle, HKDFINANC ile ticaret ettiğinizde zevk alabilirsiniz.",
			li1: "Anlık Product Quote",
			li2: "Özel teknik göstericiler",
			li3: "Ekskluziv Pazar Analizi",
			free: "Özgür (bedava)",
			view: "Bak.",
			h3: "Büyük soru yayıldı",
			p2: "HKDFINANC hizmetlerine karşılık olarak yayılan bir teklif/soru yüklüyor. Bu ücret ürün sitesinde refleks edildi ve bir pozisyon a çtığınızda yayıldığınızı ödediniz. Tartışma ücreti düzeltmediğinden dolayı, özel ürün sitesine gitmenizi ya da girmenizi tavsiye ediyoruz.",
			h4: "Depozit ve Çıkart Yüklemeleri",
			p3: "Müşterilerimizin maliyetlerini azaltmak için, çoğu durumda paranıza ulaşmak için sizi suçlamıyoruz. Ancak, üçüncü parti şirketi veya bank a, transansyon banka ücreti gibi bir depozit veya geri çekmek için size bir ücret ödeyebilir.",
			p4: "Müşterimiz hizmeti uzmanlarımızın yapılması gereken suçlamalar hakkında bilgi isteyebilirsiniz.",
			p5: "Örneğin uluslararası kredi kartı transaksyonları, banka hesabı desteklenmeyen para (Forex dönüştürüşü) içinde/dışarı ya da transaksyonlarda aktarılır.",
			h5: "Gece boyunca pozisyon maliyeti",
			p6: "Eğer Hâlâ GMT 10'de bir pozisyon tutuyorsanız (22:00 GMT), bir gece ücreti yükleneceksiniz. Her ürün için gece boyunca suçlamalar hakkında sorgulamalar özel ürün sitesi sayfasında veya günlüğüne girerek yapılabilir.",
			h6: "Diğer masraflar",
			p7: "Başka ücret yok. Ödeme politikamız tamamen transparent ve ödeme gereken her şey önceden duyulacak ve iletişim edilecek. Bu yüzden gizli ücretler hakkında endişelenmeye gerek yok."
		},
		contact: {
			h1: "Bizimle temas edin",
			address: "Adres",
			custom_email: "Müşteri Kontakt E-postası",
			business_email: "İş İşbirliği E-postası",
			h2: "Bize yaz.",
			p1: "Eğer hizmetlerimizle ilgili bir sorunuz varsa, lütfen sorunuzu teslim etmek için aşağıdaki formayı doldurun ve müşterimiz hizmet uzmanlarımızdan biri mümkün olduğunca çabuk cevap verecek. Ayrıca, Satış Merkezi ile ortağımız tarafından sağladığımız günlük ticaret stratejilerine imzalamak için lütfen aşağıdaki formun türüne 'Günlük Merkezi Analizi'ni yazın ve almak istediğiniz dil versiyonunu doldurun ve sizi kayıtlayacağız. * İmzalama hizmeti sadece mevcut HKDFINANC gerçek müşterilere ulaşılabilir.",
			h3: "Temsil prosedürleri",
			p2: "Tüm şikayetlerimiz sizin için özel bir takım olacak, araştırmak ve çözülmek için, detaylar konuşulacak.",
		},
		faq: {
			h1: "Ortak sorunlar",
			tit1: "Demo hesabının amacı nedir?",
			tit2: "Gerçek bir hesap nasıl a çacak?",
			tit3: "Pazar düzeni nasıl yaratmak?",
			tit4: "Neden emir koyamıyorum?",
			p1: "Demo hesapları genellikle ticaret arayüzü, veri ve operasyonlar hakkında gerçek hesaplar ile aynı. Demo hesapları sanal fonlarda 50.000 dolar içeriyor ve müşterilerin kendilerini platformun özellikleriyle tanıtması için tasarlanmış, benzetimli operasyonlar üzerinde fonlarını riske atmamış.",
			p2: "Canlı bir hesap a çmak için, lütfen aşağıdaki adımları takip edin:",
			p3: "1. HKDFINANC kayıt sayfasını girin ve talimatlarını takip edin: e-posta/cep telefonunuzu kaydet, giriş parolanızı ayarlayın ve otomatik bir [Demo Hesabı] yaratmak için doğrulamayı teslim edin.",
			p4: "2. Demo Hesap sayfasında 'Gerçek Hesapla değiştir' ve 'Gerçek Hesap' a çılmak için prosedürü takip edin.",
			p5: "Yaşam bir hesap a çmadan önce ticaret ile ilgili yasal açıklamaları okumanızı ve anlamanızı tavsiye ediyoruz.",
			p6: "Lütfen kimlik doğrulamasını tamamladıktan sonra sadece canlı hesabınıza yerleştirebilirsiniz.",
			p7: "'Ticaret' (ticaret) üzerine tıklayın, platformda ticaret için kullanılabilecek tüm ürünleri gösterecek, aramak için sağ tarafta ürünin kodu ya da isimi girebilirsiniz, ürüni seçin ve ticaret penceresini yukarı çıkarmak için 'SELL/BUY' (SELL/BUY) tıklayabilirsiniz. Şimdiki fiyatı ve tahmin edilen sınırı görebilirsiniz. Açılacak ticaret sayısını elle ayarlayabilirsiniz, duracak kaybını ayarlayın ve risk kontrolü için zarar alırsınız, sonra pozisyonu a çmak için 'SELL/BUY' üzerine tıklayın. Lütfen bütün mali ürünlerin fiyatları her zaman pazarla değişir ve güncelleştirir, ve pazar BUY/SELL düğmesini basmadan önce değiştirilebilir.",
			p8: "Öncelikle, lütfen internet bağlantısının normal olup olmadığını kontrol edin. Ticaret çeşitli pazarları geçici olarak kapatılan siteler veya kapatılmış pazarlar emirleri yerine getiremeye neden olabilir. Ayrıntılar için, lütfen internet müşterilerimize daha fazla yardım için temas edin.",

		},
		fundSecurity: {
			h1: "Mali güvenlik",
			p1: "HKDFINANC yetkili yönetim kurumları tarafından yetkili ve düzenlenmiş. İşleşme yaptığımız her şeyin kalbinde ve kültürümüzün kalbinde. Paralarınızın güvenliği önemlidir ve tüm müşteri depozitleri ayrı yerlerde tutuyor.",
			p2: "Retail müşterilerin depozitleri, düzenleme gerekçelerine uygun olduğu sürece güvenlik hesaplarında ayrı olarak tutuluyor.",
			p3: "HKDFINANC yasalar ve kurallar tarafından izin verilenlerden başka ticari faaliyetleri için müşteri fonları kullanmıyor.",
			p4: "HKDFINANC hiçbir pazarda tahmin etmeyecek",
			p5: "Üçüncü parti muhasebeci firmasının bağımsız denetimi",
		},
		help: {
			h1: "HKDFINANC Yardım Merkezine hoş geldiniz!",
		},
		media: {
			h1: "Ortam Merkezi",
			p1: "Bilgi görüntüle, basın yayınları, medya özellikleri, duyurular ve HKDFINANC markasıyla ilgili diğer bilgi.",
		},
		mitrade: {
			h1: "HKDFINANC hakkında",
			view_certificate: "(Kimlik metodları)",
			p1: "HKDFINANC, yetkili kurumlar tarafından yetkili ve düzenlenmiş bir finans teknoloji şirketi, basit ve uygun bir ticaret deneyimleri ile yatırımcıları sağlamaya odaklanıyor. Yenilikçi HKDFINANC çeşitli ticaret platformu, en iyi Mobil Satış Platformu ve en Yenilikçi Brokeraj gibi ödülleri birdenbire kazandı.",
			p2: "HKDFINANC platformu üzerinden uluslararası finans pazarları ile daha geniş bir menzil yatırım ve ticaret edebilirsiniz, stoklar, indikler, eşyalar, yabancı değişiklik ve yüzlerce popüler çeşitleri kaplayabilirsiniz. HKDFINANC size sadece kullanıcı dostu mobil ve web tabanlı ticaret platformlarına sınırlı değil, aynı zamanda rakip ticaret maliyetleri, hızlı ticaret yönetimi, müşteriler hizmeti ve bilgi desteğinin mükemmel bir zenginliği sağlıyor, zamanlı yatırımları ve ticaret fırsatlarını almanıza yardım ediyor.",
			p3: "HKDFINANC endüstri yetkilisi ve birçok ajansı tarafından ciddi düzenlenmiş. HKDFINANC Uluslararası, Mauritius'un Mali Hizmetler Komisyonu (FSC) tarafından lisansı numarası GB20025791 altında yetkilidir ve yönetilmiştir.Mauritius lisansının ayrıntıları için FSC'nin resmi sitesine gidebilirsiniz. https://www .fscmauritius.org",
			p4: "Sorgulama yap. HKDFINANC Global Hong Kong Güvenlik ve Yatırım Komisyonu (ASIC) tarafından onaylanan bir Finanlı Hizmetler Lisansı (AFSL 398528) sahip. Hong Kong lisansı tabağındaki daha detaylar için, lütfen www.asic.gov.au'daki ASIC resmi web sitesini ziyaret edin.",
			p5: "HKDFINANC SIB Lisans No. 1612446 altında Kayman Adaları Monat Authority (CIMA) tarafından yetkili ve düzenlenmiş. Cayman Lisans hakkında daha fazla bilgi için lütfen resmi CIMA web sitesini www.cima.ky'de ziyaret edin.",
			p6: "HKDFINANC'nin bütün işletme operasyonları ciddi kontrol altında ve tüm kurallarına uyuyor.",
			h2: "HKDFINANC Arkaplanı",
			p7: "HKDFINANC, finansal ticaret ve fintech endüstrilerinde zengin deneyimler ve bilgiler olan üst bir takımdan oluşur. HKDFINANC filozofisi transaksyonları kolaylaştırmak ve daha arkadaşlık etmek.",
			p8: "Bizim holistik stratejimiz ve iş modelimiz, endüstri geliştirme ve pazar talebinin küresel ve derinlikli bir gözlemi ve bilgimizi sağlayabilir ki kaynakları daha hedefli bir şekilde ayırabiliriz, teknolojiyi sürekli yenilemek ve etkileşimliliğini iyileştirebiliriz ve müşterilerimize daha uygun ve dost ticaret deneyimi getirmeye devam edelim.",
			h3: "Neden HKDFINANC",
			why1: "Düşük sınıf ticaret şartları",
			why2: "Sanayi yetkilileri tarafından düzenlenmiş",
			why3: "Basit ve düşünceli ticaret platformu",
			why4: "İnternet desteğinin yüksek seviyesi",
			why5: "Konkursiv transaksyon maliyetleri",
			why6: "Negatif denge koruması",
			h4: "Ödüller ve Saygılar",
			p9: "Biz her zaman müşterilerimize kalite transaksyon hizmetlerini sağlamak için mükemmel davranıyoruz.",
			p10: "HKDFINANC markası, sanayideki prestizci bir organizasyondan ödül almak için onur duyuyor, takımın s ürekli çabalarını ve müşterilerine bağlılığı tanıyıyor.",
			new_add: "Dünyada yeni kullanıcılar",
			ol1: "En iyi Haber ve Analiz Verici",
			ol2: "Asya'daki en iyi Forex Broker",
			ol3: "Hong Kongn Forex Customer Satisfaction Awards",
			ol4: "En iyi Mobil Uygulaması",
			ol5: "Hong Kong'un en hızlı büyüyen broker",
			h5: "HKDFINANC Operasyon Filosofisi",
			tip_tit1: "İlişkiler",
			tip_tit2: "Basit ama güzel.",
			tip_tit3: "Aç (gizli olmayan)",
			tip_tit4: "Yeni izleri yak.",
			tip_p1: "Tüm ticaret riski içeriyor ve HKDFINANC müşterilerine sorumlu ticaret ortamı sağlamak için fazla adım yaptı. HKDFINANC endüstri yetkilileri, birçok kurumlar tarafından ciddi düzenlenmiş ve yüksek seviye güvenlik ve stabilit standartlarının altında çalışıyor. Platformumuz riski yönetimi dahil de sorumlu ticaret araçlarını sağlamaya devam ediyor.",
			tip_p2: "Basit güzellik, detaylar harika yapıyor, HKDFINANC her detayla kullanımı kolaylaştırmaya ve kullanıcı tecrübelerine bağlı. Platformumuzu ve hizmetlerimizi en iyileştirmeye devam ediyoruz ki, yeniden deneyimli yatırımcılara kadar herkes, HKDFINANC platformu üzerinden ticaret fırsatlarından faydalanacak.",
			tip_p3: "Müşterilerimizin adil ve etkili bir ticaret çevresinden tamamen faydalı olmasına izin vermek için HKDFINANC, şiddetli ve transparent self-regulator sistemi takip ediyor, bütün servis sürecinin tamamen dürüstlüğünü ve transparentliğini sağlamak için kapsamlı ve transparent platformlu ürünlerin bilgilerini, fiyat çevresini ve verilerini sağlıyor.",
			tip_p4: "HKDFINANC, teknolojik gelişmeler, finansal gelişmeler ve daha geniş hizmetler büyümesi ve değeri yaratmaya devam edebileceğimizi güçlü düşünüyor. Bu yüzden açık düşünceleri teşvik ediyoruz ve gelişmeleri terfi etmeye devam ediyoruz, finansal teknolojide yeni bir yönetici ve lider olmaya amaçlı.",
			row6_tit1: "Görevimiz",
			row6_tit2: "Gözümüz",
			row6_tit3: "İş İhtiyarlıları",
			row6_p1: "Uzay, zamanı ve pazarlıkla sınırlı olmayan adil, etkili ve transparent ticaret çevresini sağlamak ve ticaret etmek isteyen daha fazla insan için ve finansal ve teknolojik yenileme endüstrisini yönetmek için.",
			row6_p2: "Teknolojik gelişmeler, finansal gelişmeler ve çeşitli hizmetler büyütmemizi sürükleyen ve toplumda değer yaratmaya devam etmemizi sağlayan güçler olduğuna inanıyoruz.",
			row6_p3: "Bu yüzden, açık aklımızı teşvik edip, yenilikleri terfi edip, sürekli büyüme ve ilerleme aracılığıyla olmayı amaçladık.",
			row6_p4: "Dünya'nın önderli yabancı değişim şirketi",
			row6_p5: "7 ülkedeki küresel bir takımız ve hızlı büyüyen bir takımız ile hep bize katılması için profesyonel arıyoruz. Birçok kültürel takımıyla sevdiğinizi yapın!",
			row6_p6: "Lütfen bize yeni devam ettiğinizi ve neden bizimle çalışmak istiyorsunuz:",
			row6_p7: "Alternatif olarak, daha fazlasını öğrenebilirsiniz",
			row6_p8: "Belki de",
			row6_p9: "Açık tatillerimizi açıklayın.",
			row6_p10: "HKDFINANC kişisel bilgilerinizin güvenliğini kesinlikle koruyor. HKDFINANC iş uygulaması sürecinde size finansal bilgi istemeyecek. Bizim toplantımız için finansal bağlantı, kredi kartı, banka hesabı bilgileri veya her türlü ödeme gerekmiyor.",
			row6_p11: "Eğer bir pozisyonun sahte olduğunu şüpheleniyorsanız, lütfen bizimle arayın.",
			row6_p12: "HKDFINANC'ın İnsan Kaynakları Bakanlığıyla temas edin. Eğer yanlış bir iş göndermesi tarafından aldatıldığınızı doğrularsanız, lütfen ilişkili organizasyon bölümünüze bağlantı edin.",

		}
	},
	product: {
		row2: {
			tit: "Satış çeşitleri",
			sell: "Satın",
			buy: "Alın.",
			trade: "Anlaşmalar",
			more_product: "Daha fazla ticaret ürünleri",
		},
		row3: {
			tit: "Çoklu aygıt aktarımlarını destekler",
			tit1: "Çoklu aygıt aktarımlarını destekler",
			p1: "HKDFINANC web tabanlı, mobil ve masaüstü ticaret platformları sağlıyor. Her zaman, her yerde ticaret edebilirsin.",
			download: "Endirme",
		},
		row4: {
			tit: "HKDFINANC seçin",
			tit1: "Basit ve mantıklı platformu",
			p1: "Tüm sitetler, ticaret, hesap yönetimi, bilgi, riski yönetimi, güçlü fonksiyonların integrasyonu, daha iyi bir kullanıcı deneyimini elde etmek için",
			tit2: "Konkursiv transaksyon maliyetleri",
			p2: "0 ticaret komisyonu, çok rekabetçi ve transparent düşük yayılar, düşük SWAP'lar, bu yüzden düşük maliyetli ticaret tadını çıkarabilirsiniz!",
			tit3: "Düşük sınıf ticaret şartları",
			p3: "Her transaksyon için en az küçük ölçü 0,01 kadar düşük ve düşük sınırla bir pozisyonu a çabilirsiniz.",
			tit4: "Yetki tarafından düzenlenmiş",
			p4: "Sanayi yetkilileri tarafından yetkili ve düzenlenmiş, tüm müşterilerin depoları ayrı olarak saklanır ve müşterilerin mallarının güvenliğini korumak için ayrı ayrı ayrılır.",
			tit5: "Negatif denge koruması",
			p5: "Hesapınız asla pazar koşulları altında müdüründen fazlasını kaybetmeyecek ve negatif dengeler riski yönetim yeteneğinizi arttırmak için zamanında temizlenecek.",
			tit6: "İnternet desteğinin yüksek seviyesi",
			p6: "İnternet müşterileri hizmetini hızlı cevap verin, profesyonel takımın gönderilmeyen çabaları ile daha iyi hizmet sağlayacak.",
		},
		row5: {
			tit: "İlişkili sorunlar",
		},
		commodities: {
			title: "Ülke ticareti",
			p: "Kıymetli metaller ve enerji gibi eşyalar, çeşitli yatırımların önemli bir parças ı olarak kabul edilir, çünkü onlar inflasyon kanıtlı olarak kabul edilir ve yatırımlara değer eklemek için bir fırsat vardır. Onların arasında altın, gümüş ve yağ büyük volumlarda ticaret edilen eşyalar, açık ve transparent fiyatları ve yüksek likvidiyeti yatırımcılar tarafından büyük bir şekilde hoşgeldiler. Ücret fiyatları, hediye ve talep, ekonomik ve politika durumları ve valetler gibi faktörler yüzünden önemli bir şekilde değiştirir, riske dönüş fırsatları sağlayarak.",
		},
		forex: {
			title: "Dışişleri ticareti",
			p: "Forex ticareti, bir para başkasına dönüştürücüdür ve yabancı takas pazarı dünyadaki en ağır ticaret mali pazarıdır. Dışişleri değiştirme pazarında ödeme yapmak, mali hareketlerin riskine karşı hedef alan, ya da kazanmak için kullanılan bir sürü iştirakçı var. Forex ticareti dünya boyunca bankalar, kurumlar ve özel ticareti a ğzından oluşturuyor. Günde 24 saat, haftada 5 gün, riskli ödül için fırsatlar sağlıyor.",
		},
		indices: {
			title: "Hindiler",
			p: "Bir stok indeksi, özel bir değişiklikte özellikler türünü temsil eden bir istatistik değerdir ve pazarda özel bir çeşit düzey stoklarının genel fiyat seviyesi ve hareketinin göstericisidir. Tüm pazar veya sektörde yatırım fırsatlarını etkileyen satış hesapları bireysel kayıtlara yatırım yapma riskini azaltır. Amerika, Avrupa, Asya ve Hong Kongn pazarlarında en popüler indikler, Hong Kong 200, Amerikan Teknik 100, Hong Kong 50, Almanya 30, etc., küresel stok pazarlarında farklı fırsatlar sunuyor.",
		},
		shares: {
			title: "Paylaşım",
			p: "Kaynaklar bir değişiklikte emin edilen güvenlik türüdür. Parayı toplamak için, şirketin özelliğini listeden bir değişiklik aracılığıyla güvenlik maliyetleri ile ayıracaklar. 2019 yılında tek başına, dünya kaynaklarının toplam ticaret volume 60 trilyon dolarını a ştı, büyük ölçek ve güçlü likvidiyet ile. Bu yüzden, birçok yıl sonra bile, küresel yatırımcıların gözünde en popüler finans aletlerinden biri kalır. stock fiyatı, bütün ekonomi, endüstri ihtimalleri ve şirket operasyonları gibi çeşitli faktörler tarafından etkilenir. Yatırım ve ticaret için her zaman yüksek koşurluğunluğu ve fırsatları ile.",
		},
		coins: {
			title: "Şifreler",
			p: "Şifreler kodla oluşturulmuş bir dijital para. Onlar geleneksel banka ve hükümet sistemlerinin dışında özel olarak çalışıyorlar. Adı önerdiğine göre, kriptocurrencies, transaksyonları güvenli olarak kullanır ve diğer birimlerin yaratılmasını sağlar. Bitcoin orijinal ve şu anda en ünlü kriptovalet olarak, Bitcoin Satoshi Nakamoto tarafından yarattı ve Ocak 2009'da başlattı. Bugün bile, adın Satoshi Nakamoto'nun bir kişiye ya da bir grupa insana referans olup olmadığını bilmiyor. Bitcoin ilk decentralized kriptocurrency olarak kabul edilir. Bütün kriptocurrenciler gibi, dağıtılmış halk kitabı olarak servis eden blok zinciri aktarım veritabanından kontrol edilir. Şimdiye kadar, 1000'den fazla kriptovalet internette satılıyor."
		}
	},
	trade: {
		data_sources: "Veri kaynakları",
		tip: " Yukarıdaki bilgi sadece referans için. HKDFINANC bilgilerin doğruluğunu, zaman sınırlığını ya da tamamlamanı garanti etmez ve verilen bilgilerin haksız güvenini yerleştirmemelisiniz. Bu bilgiler fiyat kayıtlarımızı ya da finansal aletleri ticaret etmek için bir teklif ya da talep etmez. HKDFINANC finansal danışma şirketi değil ve sadece bir idam doğası hizmetleri sağlıyor. Okuyucuların kendi yatırım tavsiyesini aramasını tavsiye ediyoruz. Lütfen tam açıklayıcımızı görün.",
		tip2: "Her cuma saat 15:00'de güncelleştirildi (GMT)",
		tip3: "Trading Perspectives, pazar ticaret duygularını tahmin etmek için bir araç, endüstri uzmanlarının kısa ve orta süre görüntülerini ve her sıcak ürün için tren tahmini gösterecek.",
		analysis: {
			tit: "Trading Strategies",
			all: "Tam",
			day: "Birkaç gün içinde",
			tit1: "Trading Strategies",
			tit2: "Tehnik Görüntüle",
		},
		calendar: {
			tit: "Mali takvim",
			prev_week: "Geçen hafta",
			next_week: "Gelecek hafta",
			place_date: "Arama Tarihi",
			select: "Seçenekler",
			select1: "Ülkemiz",
			select2: "Önemli",
			start_time: "Başlama zamanı",
			end_time: "Bitir zamanı",
			search: "SH'i arayın.",
		},
		contract: {
			tit: "Kontrakt Özellikleri",
			type1: "Tam",
			type2: "Forex",
			type3: "Merkez",
			type4: "Görüntüler",
			type5: "US stok",
			type6: "Hong Kongn paylaşımları",
			tit1: "Büyük yabancı değişim",
			tit2: "Küçük yabancı değişim",
			tit3: "Merkez",
			tit4: "Ana indikler",
			tit5: "Küçük indeks",
			tit6: "US stok",
			tit7: "Hong Kongn paylaşımları",
			tit8: "Genel kurallar",
			p1: "Güçlü düzlük oranı",
			p2: "Bekleyen sıra değerliği",
			p3: "Sınırsız",
			p4: "Gece ödeme saati",
			p5: "Günlük anlaşma; Yaz: 05:00, Kış: 06:00",
			p6: "Dönüştürme, gece oranları, etc.",
			p7: "Satış platformlarını görün",
			p8: "Yukarıdaki tüm ticaret çeşitlerine uygulanır.",
			tit9: "Satış Hafta Takvimi",
			p9: "Gizli",
			p10: "Yukarıdaki ticaret saatleri tatillerin veya özel pazar ayarlarının etkisini kabul etmez.",
			p11: "Özellikle ticaret zamanı tavsiyeleri",
			p12: "Giriş Platformu",
			p13: "Önemli çeşitlerin profillerini kontrol edin.",
		},
		forecast: {
			tit: "Trading Perspectives",
			p1: "Orta değer",
			p2: "Bullish",
			p3: "Bearish",
			p4: "Yandan",
			p5: "Orientasyonlar",
		},
		news: {
			tit: "Gerçek zamanlı haberler",
		},
		platforms: {
			tit: "HKDFINANC ticaret platformu",
			p: "Basit ve intuitiv arayüz, harika ticaret performansı, daha fazla yatırımcıların ihtiyaçlarına uyuyor. Bugün HKDFINANC ile ticaret başlayın.",
			tit1: "HKDFINANC ticaret platformu uygun ve kolay kullanılabilir.",
			tit2: "Her zaman, HKDFINANC uygulaması ile her yerde ticaret edin!",
			li1: "Fonların güvenliğini sağlamak için yetkili kurallar ile yüzlerce popüler pazarlar ticaret eder",
			li2: "Gerçek zamanda pazar trenlerini izle, ticaret analizini görün ve son mali haberleri araştırın.",
			li3: "Her zaman farklı tür ticaret çizelgelerini görüntüle ve çizeltme fonksiyonlarını hiçbir hassle olmadan uygula",
			li4: "Android veya iOS aygıtlarınızda HKDFINANC'ın ödül kazanan ticaret platformunu deneyin!",
			code_download: "İndirmek için kodu tarayın",
			tit3: "Tarayıcınızın üzerinden indirmeye, internette ticaret etmeye gerek yok.",
			li5: "İndirme gerekli yok, bilgisayar tarayıcınızdan doğrudan internetten ticaret edin",
			li6: "Ödül Kazanmak: Asya 2022'daki en iyi Forex Broker, Hong Kong 2022'deki en iyi ticaret platformu, Dünya 2022'deki en hızlı Büyüyen Forex Fintech Broker",
			li7: "Güçlü teknik belirtileri sağlar: MACD, KDJ, RSI, TRIX, DMA, CCI, etc.",
			li8: "İşletimlerinizin potansiyel riskini azaltmak için durma kaybı ve negatif dengeleme koruması gibi riske yönetim araçlarıyla eşleştirilmiş.",
			tit4: "Masaüstü aktarımlarınızın mükemmel çözümü",
			li9: "Kendi seçili listede pazar sitelerini kolayca görüntüle",
			li10: "Fiyat değiştirme uyarıları ve diğer bilgi uyarıları almak için uyarıları aç",
			li11: "Çoklu grafik düzenleri bazayı farklı zamanlı boyutlardan izlemeye izin verir.",
			li12: "Anlaşma belirtileri, ticaret duyguları ve ticaret analitikleri hepsi bir arayüzde, değiştirme gerekli değil",
			li13: "HKDFINANC'daki son ticaret haberlerini doğrudan alın!",
			tit5: "Neden HKDFINANC ticaret platformunu seçiyorsun?",
			row5_p1: "Güvenlik",
			row5_p2: "%0 komisyon",
			row5_p3: "Çok platformlu destek",
			row5_p4: "Teknik Çizelgeler",
			row5_p5: "Her zaman, her yere erişin",
			row5_p6: "Çeşitli çizim araçları",
		},
		quotes: {
			tit: "Gerçek zamanlı sitetler",
			p1: "Sana şu anda popüler mali enstrümanlar hakkında anlık siteleri, pazar duyguları ve ilişkileri sağlayan haberler sağlar.",
			hot: "Popüler",
		},
		risk: {
			tit: "Risk Yönetim Sistemi",
			p1: "Tüm ticaret riski içeriyor. HKDFINANC tarafından sağladığı özgür risk yönetim araçlarını kullanarak pazar koşullarına rağmen riskinizi etkili kontrol edebilirsiniz.",
			function_tit1: "Profit/Stop Loss Function",
			function_tit2: "İlerleme Durma Funksiyonu",
			function_p1: "Paraya kilitlemek",
			function_p2: "Limit kaybı",
			function_p3: "Faylı kilidini büyüt",
			function_p4: "Yerlerini otomatik izlemeye gerek yok",
			function_p5: "Kaybı kapatma fiyatının ayarlaması",
			row2_tit1: "Profit/Stop Loss Function",
			row2_p1: " Yeni bir düzeni oluşturduğunda ya da mevcut bir düzeni değiştirdiğinde 'Profit alın' ve 'Kaybetmeyi kes' seçeneğini teklif ediyoruz. Ayarlandığında, emir normalde ayarladığınız hedef fiyatına kapanacak, hedefinize ulaştığınız zaman kazanınızı kilitlemeye yardım eder ya da pazar uygun hareket ederse kaybınızı azaltmak için. Lütfen, pazar volanlığı yüzünden herhangi bir emir kısayılabileceğini unutmayın. Bu durumda sistemin öntanımlı fiyatla emiri gerçekleştirmeyecek, ama hedef fiyatından gelecek en favori fiyatla konumunu kapatacak.",
			row2_tit2: "Örnek ",
			row2_p2: "Ağımdaki fiyat EUR/USD 1.13816/1.13837'dir (satın/satın). 1 kütle (1 kütle = 100,000 EUR) için bir satın emri a çarsınız ve 1,13806'de bir Stop Kaybını ayarlarsınız.",
			row2_p3: "Genelde fiyat 1,13806'e düştüğünde, durak kaybı emriniz tetiklenecek ve pozisyon 31 dolar karşılaştırılması için 1,13806'da kapanacak.",
			row2_p4: "Ancak, pazar çevresinde aniden bir deği şiklik oluştuğunda ve fiyat sürücülerine doğrudan 1,13837'dan 1,13795'e doğrudan geçerken, Stop Kayıp hedefinizi doğrudan atlatırken, pazar atlayıp sistem pozisyonu 1,13806'de kapatamaz, fakat sonraki en iyi fiyatla, yani 1,13795'de çalışacak, ve son kayıp 42 USD olacak.",
			row3_tit1: "İlerleme Durma Funksiyonu",
			row3_tit2: "Örnek",
			row3_p1: "Arama durakları (ayrıca izleme durakları olarak bilinen) güçlüdür, çünkü onlar sizin kazançlarınızla otomatik olarak değiştirirler, kazançlarınızı büyütmenize ve kaybetmenizi sürekli kontrol etmenize izin verirler. Yeni bir emir oluşturduğunda, sadece izleme durağını ayarladın ve fiyat senin için hareket ettiğinde, durak düzeniniz son fiyatla otomatik olarak yenilenecek. Üstelik, fiyat size karşı hareket ettiğinde durma kaybı emri etkinleştirilecek ve durum kaybı için ayarladığınız uzakta kapatılacak. Lütfen, pazar etkisizliği yüzünden herhangi bir emir kısayılabilir, bu durumda sistemin öntanımlı fiyatınıza göre emiri gerçekleştiremeyecek, fakat hedef fiyatından gelecek en favori fiyatla sizin konumunuz kapatacak.",
			row3_p2: "Ağımdaki fiyat EUR/USD 1.13816/1.13837 (satın/satın). 1,13837'de 100 pips kaybıyla bir sürü satın alın düzeni oluşturdunuz (0,00100).",
			row3_p3: "Ürünün fiyatı 1,13816'de olduğunda, Stop Kaybınız 1,13716. Eğer ürünün satış fiyatı 1,13845'e kadar taşınırsa, Durum Kayıtları ayarladığınız mesafeye göre güncelleştirilecek ve güncelleştirilen Stop Kayıtları 1,13745 olacak.",
			row3_p4: " Bunun tersine, ürün fiyatı 1,13845'den 1,13745'e kadar sürdüğünde, izleme durağı etkinleştirilecek ve pozisyon 1,13745'e kapanacak.",
			tip: "Nokta: Genelde, mali aletin fiyatında en küçük hareket birimi bir nokta denir. HKDFINANC platformunda, bir enstrümanın fiyatının son sayıs ını ya da on sayısını ifade ediyor.",
		},
		sentiment: {
			tit: "Duygusal indeks",
			type1: "Tam",
			type2: "Forex",
			type3: "Merkez",
			type4: "Görüntüler",
			long: "Uzun süre (finans)",
			short: "Kısa",
		},
	},
	layout: {
		aside: {
			nav1: "Anlaşmalar",
			nav2: "Pazar",
			nav3: "Haberler",
			nav4: "Üniversitesi",
			nav5: "Benim.",
			newsDialog: {
				title: "Haber Merkezi",
				type1: "Sistem bildirimi",
				type2: "Bulletin",
				allRead: "Hepsini okuyulmuş olarak marka edin",
			},
			settingDialog: {
				title: "Ayarla",
				nav1: "General",
				nav2: "Tercihleri göster",
				nav3: "Sistem Bilgisi",
				logout: "Çıkış",
				setting1: "Çok dilli",
				setting2: "Anlaşmalar",
				setting2_tip: "Otomatik olarak seçilen çeşitleri açık konumlara ekle",
				setting3: "Geri bilgi gönder",
				setting3_tip: "Funksiyonel Tükseller",
				setting4: "Gerçek isim doğrulaması",
				setting4_tip: "Gerçek isim doğrulaması",
				setting5: "Tema Modu",
				setting5_label1: "Obscurely",
				setting5_label2: "Işık renk",
				setting6: "Yükselme veya düşen renk",
				setting6_label1: "Lit. yeşil yükselir ve kırmızı düşer.",
				setting6_label2: "Lit. Kırmızı yükseliyor, yeşil düşüyor.",
				setting7: "Çizelge Kurulumu",
				setting7_label1: "Standart Düzenleme",
				setting7_label2: "TradingView Edityon",
				setting8: "Anlaşma ve Anlaşma",
				setting8_label1: "Gizlilik politikası",
				setting8_label2: "Produkt Açıklama Paylamı",
				setting8_label3: "Risk Açıklama Görüntüsü",
				setting8_label4: "Müşteriler anlaşması",
				feedback_title: "Funksiyonel Tükseller",
			}
		},
		footer: {
			hot: "Popüler çeşitler",
			sort: "Simbol",
			code: "Kodlama",
			buy_price: "Fiyat almak",
			sell_price: "Satıcının fiyatı",
			chg: "Yükselme ya da fiyata düşürün",
		},
		header: {
			place_search: "Satış çeşitleri arayın",
			balance: "Mevcut",
			balance_tip: "Mevcut: the amount of money in the account that can be used to open new positions",
			profit_loss: "P/L",
			asset_view: "Varlıkların gözlemi",
			netValue: "Ağ değeri",
			netValue_tip: "Ağ değeri: şu anda hesap değeri, bütün pozisyonların kazanılması ve kaybı dahil",
			marginLevel: "Margin seviyesi",
			margin: "Margin (türevli ticaret içinde)",
			margin_tip: "Margin:",
			maintainsMargin: "Yedekleme sınırı",
			maintainsMargin_tip: "Güvenlik Margin: Bütün pozisyonlarınızı tutarken hesabınızda tutmalı en az sınır miktarı.",

		},
	},
	table: {
		label_name: "İsim",
		label_buyPrice: "Alın.",
		label_sellPrice: "Satın",
		label_variableValue: "Değiştir",
		trade: "Anlaşmalar",
		label_code: "Simbol",
		label_title: "İsim",
		label_start: "Önemli",
		label_country: "Ülkemiz",
		label_event: "Olay",
		label_previous: "Önceki değer",
		label_predictive: "Projekt değeri",
		label_announced: "Yayınlanmış değer",
		label_stop_loss_min: "Duruşma emirleri için en az mesafe",
		label_difference: "Dynamic Spreads",
		label_contract_size: "Kontrakt boyutu",
		label_max_hand: "Tek bir transaksyon için en büyük ölçü",
		label_min_hand: "En azından fazla ölçü",
		label_trade_week: "Satış Hafta Takvimi",
		label_week: "1 hafta",
		label_month: "1 Ocak",
		label_quarter: "1 quarter",
		label_dailyTrend: "Tek Gün Trend",
		label_tradeId: "Transaksyon Kimliği",
		label_breed: "Simbol",
		label_tradeType: "Transaksyon türü",
		label_currency: "Para",
		label_amount: "Para",
		label_balance: "Balanlar",
		label_type: "Tikoloji",
		label_time: "Zaman",
		label_orderNumber: "OrderNum",
		label_pointer_number: "Elinden",
		label_price_money: "Aç",
		label_stop_win_price: "Parayı al.",
		label_stop_lose_price: "Dur-kaybı",
		label_openTime: "OpenTime",
		label_profit_loss: "P/L",
		label_sell_buy: "Fiyatı sorun/alın",
		label_chg: "Yükselme ya da fiyata düşürün",
		label_sell_price: "Satıcının fiyatı",
		label_buy_price: "Fiyat almak",
		label_condition: "Öncelikle",
	},
	form: {
		label_feedback: "Sorunlar ve tavsiyeler",
		place_feedback: "Lütfen sorunuzu ya da teklifinizi girin",
		label_img: "Fotoğraf",
		label_img_tip: "Seçeneksel, sorunun ekran fotoğrafını sağlayın",
		feedback_tip: "Acil bir sorunuz varsa, lütfen temas edin.",
		online: "İnternet Müşterisi Hizmeti",
		label_name: "İsimler",
		place_name: "Lütfen isminizi girin",
		label_email: "E-posta adresi",
		place_email: "E-posta adresi",
		message_email: "Lütfen e-posta adresinizi girin",
		label_nationality: "Şehir",
		place_nationality: "Lütfen uluslarınızı girin.",
		place_questionType: "Sorun türü",
		message_questionType: "Lütfen türü seçin",
		questionType0: "Satış platformu",
		questionType1: "Yapılar ve Beslemeler",
		questionType2: "Günlük pazar analizine yazın",
		questionType3: "Müşteri hizmeti",
		questionType4: "Diğerleri",
		place_question: "Sorunuz.",
		message_question: "Lütfen bir soru girin",
		submit_question: "Soruların teslim edilmesi",
		label_phone: "Cep telefonu numarası",
		place_phone: "Lütfen cep telefonunuzu girin.",
		message_phone: "Lütfen cep telefonunuzu girin.",
		label_password: "Kriptografik",
		place_password: "Lütfen parolanızı girin",
		message_password: "Lütfen parolanızı girin",
		label_confirmPassword: "Parola doğrula",
		place_confirmPassword: "Lütfen parolanızı tekrar girin",
		message_confirmPassword: "Lütfen parolanızı tekrar girin",
		label_captcha: "CAPTCHA, challenge-response test türü (hesaplama)",
		place_captcha: "Lütfen doğrulama kodunu girin",
		message_captcha: "Lütfen doğrulama kodunu girin",
		get_captcha: "Kontrol Kodu",
		label_inviteId: "Davet kodu",
		place_inviteId: "Davet Kodu (seçeneksel)",
		to: "- Evet.",
		start_time: "Başlama zamanı",
		end_time: "Son zamanı",
		label_cardNumber: "Sertifikat numarası",
		place_cardNumber: "Lütfen kimlik numarasını girin",
		message_cardNumber: "Lütfen kimlik numarasını girin",
		label_cardMain: "Belgenin önünde",
		message_cardMain: "Lütfen belgelerin önünü girin",
		label_cardBack: "Belgenin tersi tarafı",
		message_cardBack: "Lütfen belgelerin tersi tarafını girin",
		confirm_modify: "Değişikliklerin doğrulaması",
		label_realName: "Tam isim",
		place_realName: "Lütfen isminizi girin",
		message_realName: "Lütfen tam adınızı girin",
		label_firstName: "Soyadı",
		place_firstName: "Lütfen soyadınızı girin",
		message_firstName: "Lütfen soyadı girin",
		label_lastName: "İsim",
		place_lastName: "Lütfen orta isminizi girin",
		message_lastName: "Lütfen bir isim girin",
		label_birthday: "Doğum günü",
		place_birthday: "Lütfen doğum gününüzü girin",
		message_birthday: "Lütfen doğum gününüzü girin",
		place_nowPsd: "Lütfen şu anda parolanızı girin",
		message_nowPsd: "Lütfen şu anda parolanızı girin",
		place_newPsd: "Lütfen yeni bir parola girin",
		message_newPsd: "Lütfen yeni bir parola girin",
		place_crmPsd: "Lütfen yeni parolanı doğrulayın",
		message_crmPsd: "Lütfen yeni parolanı doğrulayın",
		label_breed: "Simbol",
		place_breed: "Lütfen bir tür girin",
		label_phase: "Ol.",
		label_buyPrice: "Fiyat almak",
		label_sellPrice: "Satıcının fiyatı",
		label_do: "Çünkü...",
		label_height: "Daha büyük",
		label_low: "Daha aşağı ol.",
		label_equal: "Ya da eşit",
		labelWalletName: "Cüzdanın İsmi",
		messageWalletName: "Lütfen cüzdanın adını girin",
		placeWalletName: "Lütfen cüzdanın adını girin",
		labelWalletAddress: "Cüzdanın Adresi",
		messageWalletAddress: "Lütfen cüzdanın adresini girin",
		placeWalletAddress: "Lütfen cüzdanın adresini girin",
		labelAmount: "Para",
		messageAmount: "Lütfen miktarı girin",
		placeAmount: "Lütfen miktarı girin",
		placeTimeLimit: "Lütfen bir zaman sınırını girin",
		messageTimeLimit: "Lütfen bir zaman sınırını girin",
	},
	pay: {
		title: "Paraya kolay erişim",
		p1: "Genelde kullanılan ödeme kanalları, hızlı depozit ve çekilme desteği",
		p2: "Ülkenizde bazı ödeme metodları ulaşılabilir.",
		p3: "HKDFINANC hizmetine hoş geldiniz!",
		btn: "Şimdi ticaret ",
	},
	header: {
		tip1: "Çabuk kaybın yüksek riski olan karmaşık bir finansal üründür.",
		btn1: "Satış platformu",
		btn2: "Çıkış",
		btn3: "İçeri gir",
		BigUint64Array: "Şimdi ticaret",
	},
	footer: {
		help: "Bizimle temas edin",
		tip1: "Toplumuzu takip edin.",
		tip2: "Lütfen, HKDFINANC resmi bir Telegram grubu kurulmadı ve HKDFINANC adında oluşturduğu her Telegram grubu, kaçırmaktan şüpheleniyor.",
		tip3: "Satış tüm paralarınızın kaybına sebep olabilir. OTC türevleri ticaret herkese uygun değil. Lütfen hizmetlerimizi kullanmadan önce yasal belgelerimizi dikkatli okuyun ve ticaret etmeden önce olan riskleri tamamen anlamanızı sağlayın. Aslında temel malların hiçbirini sahip değilsin ya da sahip değilsin.",
		tip4: "HKDFINANC satış, alış, tutma veya satış konusunda hiçbir tavsiye, tavsiye veya fikir vermez. Teklif ettiğimiz ürünler, küresel maliyetlere dayanan OTC türevleri. HKDFINANC tarafından sunulmuş tüm hizmetler sadece ticaret emirlerine dayanılır.",
		tip5: "HKDFINANC, birkaç şirket tarafından paylaşılan ve a şağıdaki şirketler tarafından çalışan bir iş markadır:",
		tip6: "HKDFINANC International Ltd, bu website HKDFINANC International Ltd'de kullanılabilecek ya da kullanılabilecek mali ürünlerin emiri, Mauritius'un Mali Hizmetler Komisyonu (FSC) tarafından lisansı numarası GB20025791 altında, 6 St Denis Street, 1. katı Nehir Mahkemesi Port Louis 11328, Mauritius'un kayıtlı adresi ile yazılmış ve düzenlenmiş.",
		tip7: "HKDFINANC Global Pty Ltd ABN 90 149 011 361, Hong Kongn Mali Hizmetler Lisansı (AFSL) numarası 398528 altında kayıtlı.",
		tip8: "HKDFINANC Holding Ltd, SIB lisansı numarası 1612446 altında Cayman Adaları Monetary Authority (CIMA) tarafından yetkili ve düzenlenmiş.",
		tip9: "Bu sitedeki bilgiler Amerika, Kanada, Japon veya Yeni Zelanda'nın yaşayanları için desteklenmiyor. Yerel yasalara ya da kurallara karşı kullanılacak herhangi bir ülke veya mahkemedeki herhangi biri tarafından kullanılmak istemiyor. Lütfen, İngilizce hizmetlerimizin ilk dili ve sözleşme belgelerimizin yasal olarak bağlı dilidir. Diğer dillere çeviriler sadece referans için, ve İngilizce ve Çin versiyonları arasındaki herhangi bir ayrılığın durumunda İngilizce versiyonu yenecek.",
		tip10: "SSL güvenli iletişim şifrelemesi. © HKDFINANC Tüm haklar rezerve edildi.",
		link1: "Gizlilik politikası",
		link2: "Produkt Açıklama Paylamı",
		link3: "Müzakereler süreci",
		link4: "Risk Açıklama Görüntüsü",
		link5: "Müşteriler anlaşması",
		toTop: "Yapıştırıcı (bir İnternet forum düzeninden ve benzer)",
	},
	nav: {
		tit1: "Marketler",
		tit2: "Trading",
		tit3: "Öğrenin",
		tit4: "Bizim hakkımızda.",
		nav1: "Forex",
		nav2: "Görüntüler",
		nav3: "Merkez",
		nav4: "Stock",
		nav5: "Satış Platformları",
		nav6: "Trading Strategies",
		nav7: "Trading Perspectives",
		nav8: "Mali takvim",
		nav9: "Haberler",
		nav10: "Pazar Verileri",
		nav11: "Duygusal indeks",
		nav12: "Risk Yönetim Sistemi",
		nav13: "Kontrakt Özellikleri",
		nav14: "Başlatılıyor",
		nav15: "Yatırım görüntüsü",
		nav16: "Akademi",
		nav17: "HKDFINANC hakkında",
		nav18: "Ödüller ve Saygılar",
		nav19: "Ortam Merkezi",
		nav20: "Mali güvenlik",
		nav21: "Ödemeler ve suçlamalar",
		nav22: "Affiliates",
		nav23: "Bizimle temas edin",
		nav24: "ortak sorunlar",
		nav25: "Yardım Merkezi",
	},

	city: {
		albania: "Arnavutluk",
		algeria: "Aljerya",
		argentina: "Argentina",
		armenia: "Ermenistan",
		australia: "Avustralya",
		pakistan: "Pakistan",
		austria: "Avusturya",
		bahrain: "Bahrain",
		belgium: "Belçika",
		bosnia_and_Herzegovina: "Bosna ve Hercegovina",
		brazil: "Brazilya",
		brunei: "Brunei",
		bulgaria: "Bulgaristan",
		cambodia: "Kambodza",
		canada: "Kanada",
		cameroon: "KamerunName",
		chile: "Chile",
		colombia: "Columbia",
		costa_Rica: "Costa Rica",
		croatia: "Hırvatistan",
		cyprus: "Cyprus",
		czech_Republic: "Czech Republic",
		denmark: "DanmarkName",
		dominican_Republic: "Dominikan Cumhuriyeti",
		egypt: "Mısır",
		estonia: "İstonya",
		ethiopia: "Etiopya",
		finland: "Finland",
		france: "Fransa",
		georgia: "Georgia",
		germany: "Almanya",
		ghana: "Ghana",
		greece: "Yunanistan",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Çin",
		hungary: "Hungary",
		iceland: "İslandiya",
		ireland: "İrlanda",
		italy: "İtalya",
		india: "Hindistan",
		indonesia: "Indonezi",
		israel: "İsrail",
		iran: "Iran",
		iraq: "Irak",
		japan: "Japonya",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Güney Kore",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirgistan",
		laos: "Laos",
		latvia: "Latvia",
		lithuania: "Litvanya",
		luxembourg: "Luxembourg",
		macao_China: "Macao, China",
		macedonia: "Makedonya",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Meksika",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Karadağ",
		morocco: "Marokko",
		myanmar: "Myanmar",
		netherlands: " Hollanda",
		new_Zealand: "New Zealand",
		nepal: "Nepal",
		nigeria: "Nijerya",
		norway: "Norveç",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Filipinler",
		poland: "Poland",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Romanya",
		russia: "Rusya",
		republic_of_Trinidad_and_Tobago: "Trinidad ve Tobago Cumhuriyeti",
		rwanda: "Rwanda",
		saudi_Arabia: "Saudi Arapça",
		serbia: "Sırbistan",
		singapore: "Singapur",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "Güney Afrika",
		spain: "İspanya",
		sri_Lanka: "Sri Lanka",
		sweden: "İsveç",
		switzerland: "İsviçre",
		taiwan_China: "Taiwan, China",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Tayland",
		turkey: "Türkiye",
		turkmenistan: "Türkmenistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "Birleşik Arap Emirtleri",
		united_Kingdom: "Birleşik Krallık",
		united_States: "Birleşik Devletler",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaijan",
		bangladesh: "Bangladeş",
		belarus: "Belarus",
		belize: "belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "İngiliz bakire adaları",
		burkina_Faso: "burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cape Verde",
		cayman_Islands: "Cayman Adaları",
		central_African_Republic: "Merkezi Afrika Cumhuriyeti",
		chad: "Chad",
		comoros: "Komoros",
		the_Republic_of_the_Congo: "Kongo Cumhuriyeti",
		democratic_Republic_of_the_Congo: "Kongo Demokratik Cumhuriyeti",
		djibouti: "Djibouti",
		ecuador: "Ekvador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Ekvatorial Gine",
		eritrea: "ERITREA",
		fiji: "Fiji",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Greenland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Isle of Man",
		cote_d_Ivoire: "Côte d'Ivoire",
		jamaica: "Jamaika",
		jordan: "Jordan",
		lebanon: "Liban",
		lesotho: "Lesotho",
		liberia: "Liberya",
		libya: "Libya",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambik",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "the Niger",
		north_Korea: "Kuzey Kore",
		reunion: "Reunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somaliya",
		sudan: "sultan",
		suriname: "Surinam",
		eswatini: "Eswatini",
		syria: "Syria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Birleşik Devletler Virgin Adaları",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikan",
		yemen: "Yemen",
		yugoslavia: "Yugoslavia",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
};
